<template>
  <div> {{ type }}</div>
</template>
  
  
  
  <script>
  import { mapGetters } from 'vuex'
  export default {
      props: {
          object: {type: Object, required: true}
      },
      data(){
          return {
  
          }
      },
      methods: {
          
      },
      computed: {
        ...mapGetters({
            types: 'sanitaire/typePronostics'
        }),
        type(){
            let t = this.types.find(item => item.uid === this.object.type)
            if(t) return t.libelle
            return '-'
        }
      }
  
  }
  
  </script>
  <style lang="scss">
  .actions{
      a{
          
      }
  }
  </style>